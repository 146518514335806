import { Badge, BadgeProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)<BadgeProps>(({ badgeContent, showZero }) => ({
    '& .MuiBadge-badge': {
        position: 'relative',
        transform: 'unset',
        ...(!showZero &&
            badgeContent == 0 && {
                display: 'none',
            }),
    },
}));

export default function RelativeBadge(props: BadgeProps) {
    return <StyledBadge {...props} />;
}
