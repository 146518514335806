import { ArrayElement } from '@/utils/types/array-element';
import { NotificationsDocument, NotificationsQuery } from '@/graphql/generated/notifications.generated';
import {
    NotificationButton,
    NotificationTab,
    NotificationVariant,
    SingleNotificationActions,
} from '@/graphql/generated/types.generated';
import { Trans, useTranslation } from 'react-i18next';
import {
    Avatar,
    Stack,
    Typography,
    SnackbarContent as SnackbarContentMui,
    Button,
    IconButton,
} from '@mui/material';
import {
    CheckCircleOutlineRounded,
    CloseRounded,
    ErrorOutlineRounded,
    InfoRounded,
    WarningAmberRounded,
} from '@mui/icons-material';
import { stringAvatar } from '@/utils/string-avatar';
import RelativeBadge from '../RelativeBadge';
import { MoreOptionsButton } from './MoreOptionsButton';
import dayjs from '@/libs/dayjs';
import { forwardRef } from 'react';
import { enqueueSnackbar, SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import { CustomContentProps } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { multiRedirect } from '@/utils/multi-redirect';
import { instantDownload } from '@/utils/instant-download';
import { useMutation } from '@apollo/client';
import { ReadNotificationDocument } from '@/graphql/generated/toggle-read-notification.generated';
import { CountUnreadNotificationsDocument } from '@/graphql/generated/count-unread-notifications.generated';
import { GroupNotificationsDocument } from '@/graphql/generated/group-notifications.generated';
import { handleRedirect } from '@/utils/handle-redirect';

type ActionButtonProps = Omit<SingleNotificationActions, 'type'> & {
    type: NotificationButton | number;
    notificationId: string;
    isRead: boolean;
};
const ActionButton = ({ nameKey, type, data, notificationId, isRead }: ActionButtonProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [read] = useMutation(ReadNotificationDocument, {
        variables: { id: notificationId },
        refetchQueries: [NotificationsDocument, CountUnreadNotificationsDocument, GroupNotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.single.read')),
        onError: (e) => enqueueSnackbar(e.message),
    });

    const handleOnClick = () => {
        if (type === NotificationButton.Redirect) {
            handleRedirect(data, navigate);
        }

        if (type === NotificationButton.MultiRedirect) {
            multiRedirect(data, navigate);
        }

        if (type === NotificationButton.InstantDownload) {
            instantDownload(data);
        }

        if (!isRead) {
            read();
        }
    };

    return (
        <Button variant="text" onClick={handleOnClick}>
            {t(nameKey)}
        </Button>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NotificationAvatarProps = Record<string, any> & { variant: NotificationVariant };
const NotificationAvatar = ({ variant, user }: NotificationAvatarProps) => {
    switch (variant) {
        case NotificationVariant.Error:
            return (
                <Avatar variant="rounded" sx={{ bgcolor: 'error.light', height: 32, width: 32 }}>
                    <ErrorOutlineRounded />
                </Avatar>
            );
        case NotificationVariant.Info:
            return (
                <Avatar variant="rounded" sx={{ bgcolor: 'info.light', height: 32, width: 32 }}>
                    <InfoRounded />
                </Avatar>
            );
        case NotificationVariant.Success:
            return (
                <Avatar variant="rounded" sx={{ bgcolor: 'success.main', height: 32, width: 32 }}>
                    <CheckCircleOutlineRounded />
                </Avatar>
            );
        case NotificationVariant.User:
            return <Avatar variant="rounded" alt="" src={user?.picture || undefined} />;
        case NotificationVariant.Warning:
            return (
                <Avatar variant="rounded" sx={{ bgcolor: 'warning.light', height: 32, width: 32 }}>
                    <WarningAmberRounded />
                </Avatar>
            );
        default:
            return (
                <Avatar variant="rounded" sx={{ bgcolor: user?.color, height: 32, width: 32 }}>
                    {stringAvatar(user?.name || '')}
                </Avatar>
            );
    }
};

export type SingleNotificationProps = ArrayElement<NotificationsQuery['notifications']['items']>['single'] & {
    tab: NotificationTab;
    insideStack?: boolean;
    toaster?: boolean;
    toasterId?: SnackbarKey;
};
export const SingleNotification = (notification: SingleNotificationProps) => {
    const { closeSnackbar } = useSnackbar();

    const { isRead, message, metadata, date, variant, toaster, actions, count, id, toasterId } = notification;
    return (
        <Stack
            p={(theme) => theme.spacing(1, 1.5)}
            sx={{
                ...(!toaster && {
                    ':hover': {
                        bgcolor: 'action.hover',
                        ['.MuiIconButton-root']: {
                            visibility: 'visible',
                        },
                    },
                }),
            }}
        >
            <Stack direction="row" gap={2}>
                <NotificationAvatar variant={variant} {...metadata} />
                <Stack flex={1}>
                    <Stack position="relative" direction="row">
                        <Typography variant="caption" color="text.primary">
                            <Trans
                                i18nKey={message}
                                components={{
                                    b: <strong />,
                                }}
                                values={metadata}
                                count={count}
                            />
                        </Typography>
                        {!toaster && <MoreOptionsButton {...notification} absolute />}
                        {toaster && (
                            <IconButton
                                onClick={() => closeSnackbar(toasterId)}
                                size="small"
                                sx={{
                                    m: (theme) => theme.spacing(-1, 0, 0, 'auto'),
                                    height: 'fit-content',
                                }}
                            >
                                <CloseRounded fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center" mt={0.5}>
                {actions?.map((action, i: number) => (
                    <ActionButton key={i} {...action} isRead={isRead} notificationId={id} />
                ))}
                <Typography
                    variant="caption"
                    fontSize={10}
                    color="text.disabled"
                    m={(theme) => theme.spacing(0, 1, 0, 'auto')}
                >
                    {dayjs(date).fromNow()}
                </Typography>
                {!isRead && !toaster && <RelativeBadge variant="dot" color="info" />}
            </Stack>
        </Stack>
    );
};

type SingleNotificationToasterProps = { single: SingleNotificationProps } & CustomContentProps;
export const SingleNotificationToaster = forwardRef<HTMLDivElement, SingleNotificationToasterProps>(
    ({ ...props }, ref) => {
        let borderColor;
        switch (props.single.variant) {
            case NotificationVariant.Warning:
                borderColor = 'warning.light';
                break;
            case NotificationVariant.Error:
                borderColor = 'error.light';
                break;
            default:
                borderColor = 'white';
        }

        return (
            <SnackbarContent ref={ref}>
                <SnackbarContentMui
                    ref={ref}
                    message={<SingleNotification {...props.single} toaster toasterId={props.id} />}
                    sx={{ '.MuiSnackbarContent-message': { border: 2, borderColor } }}
                />
            </SnackbarContent>
        );
    },
);
SingleNotificationToaster.displayName = 'SingleNotificationToaster';
