import { Backdrop, CircularProgress } from '@mui/material';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

const Context = createContext<{
    loading: boolean;
    setGlobalLoading: Dispatch<SetStateAction<boolean>>;
} | null>(null);

export function GlobalLoadingProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(false);

    return (
        <Context.Provider value={{ loading, setGlobalLoading: setLoading }}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </Context.Provider>
    );
}

export function useGlobalLoading() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
