import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

const ActionButton = styled(Button)<LinkProps>(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: 'max-content',
    },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
    },
}));

export default function PromoCodeTrial() {
    const { t } = useTranslation();

    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <ResponsiveStack
                direction="row"
                gap={7.5}
                alignItems="center"
                justifyContent="center"
                maxWidth={924}
            >
                <Stack alignItems="center" justifyContent="center" gap={4.5}>
                    <Box component="img" src="/information_3d.webp" alt="" height={256} width={256} />
                    <Typography variant="h1" color="text.primary">
                        {t('trial.extend.success.title')}
                    </Typography>
                    <Stack gap={2}>
                        <Typography variant="h5" color="text.primary" align="center" whiteSpace={'pre-line'}>
                            {t('trial.extend.success.support-text')}
                        </Typography>
                    </Stack>
                    <ActionButton size="large" variant="contained" to={`/`} LinkComponent={Link}>
                        {t('trial.extend.success.action.continue')}
                    </ActionButton>
                    <Typography variant="caption" color="text.secondary">
                        <Trans
                            i18nKey={'trial.extend.success.helper-text'}
                            components={{
                                email: (
                                    <Typography
                                        component={Link}
                                        to="mailto:sayhello@swonkie.com"
                                        variant="caption"
                                        color="primary.main"
                                        display="inline-block"
                                        sx={{ textDecoration: 'none' }}
                                    />
                                ),
                            }}
                        />
                    </Typography>
                </Stack>
            </ResponsiveStack>
        </Stack>
    );
}
