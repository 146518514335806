import { ApolloQueryResult, QueryResult } from '@apollo/client';
import { BaseSyntheticEvent } from 'react';

export const loadMoreScroll = (
    e: BaseSyntheticEvent,
    loadMoreAt: number,
    hasMore: boolean | null | undefined,
    query: <T>() => Promise<QueryResult | ApolloQueryResult<T>>,
) => {
    const scrollPosition =
        e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight);

    if (scrollPosition <= loadMoreAt && hasMore) {
        query();
    }
};
