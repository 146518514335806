import { AppInitialLoadQuery } from '@/graphql/generated/app-initial-load.generated';
import { SessionDocument, SessionQuery } from '@/graphql/generated/session.generated';
import { useLazyQuery } from '@apollo/client';
import posthog from 'posthog-js';
import { productFruits } from 'product-fruits';
import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

const Context = createContext<{
    me: SessionQuery['me'];
    loading: boolean;
    refetch: () => void;
} | null>(null);

export function SessionProvider({
    initialValue,
    children,
}: {
    initialValue: AppInitialLoadQuery;
    children: React.ReactNode;
}) {
    const [session, setSession] = useState(initialValue.me);
    const [getMe, { loading }] = useLazyQuery(SessionDocument, { fetchPolicy: 'network-only' });
    const { i18n } = useTranslation();
    // update context when initial query updates (ex.: apollo store reset)
    useEffect(() => {
        setSession(initialValue.me);
    }, [initialValue.me]);

    const fetch = () => {
        getMe().then((data) => {
            if (data.data?.me) {
                setSession(data.data.me);
            } else if (data.error?.graphQLErrors[0]?.extensions?.code !== 'FORBIDDEN') {
                throw new Error('Failed to fetch session.');
            }
        });
    };

    useEffect(() => {
        Sentry.setUser({
            id: session.publicId,
            email: session.email,
        });

        // Set PostHog Identity
        posthog.identify(session.publicId.toString(), {
            name: session.name,
        });

        productFruits.init(import.meta.env.VITE_PRODUCT_FRUITS_CODE, session.language.code.split('-')[0], {
            username: session.publicId.toString(),
        });

        // Change Language
        i18n.changeLanguage(session.language.code || 'en');
    }, [session, i18n]);

    return (
        <Context.Provider
            value={{
                me: session,
                refetch: fetch,
                loading,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useSession() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
