import * as Types from './types.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChangePaymentMethodMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ChangePaymentMethodMutation = { __typename?: 'Mutation', changePaymentMethod: string };

export type DefaultPaymentMethodQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DefaultPaymentMethodQuery = { __typename?: 'Query', defaultPaymentMethod?: { __typename?: 'PaymentDefaultMethod', paymentMethod: Types.PaymentMethod, card?: { __typename?: 'PaymentMethodCard', brand: string, expiration: string, expired: boolean, last4: string } | null, paypal?: { __typename?: 'PaymentMethodPaypal', email: string } | null } | null };


export const ChangePaymentMethodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"changePaymentMethod"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changePaymentMethod"}}]}}]} as unknown as DocumentNode<ChangePaymentMethodMutation, ChangePaymentMethodMutationVariables>;
export const DefaultPaymentMethodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"defaultPaymentMethod"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"defaultPaymentMethod"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentMethod"}},{"kind":"Field","name":{"kind":"Name","value":"card"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"brand"}},{"kind":"Field","name":{"kind":"Name","value":"expiration"}},{"kind":"Field","name":{"kind":"Name","value":"expired"}},{"kind":"Field","name":{"kind":"Name","value":"last4"}}]}},{"kind":"Field","name":{"kind":"Name","value":"paypal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<DefaultPaymentMethodQuery, DefaultPaymentMethodQueryVariables>;