import { ResendRegisterConfirmEmailDocument } from '@/graphql/generated/resend-register-confirm-email.generated';
import { useMutation } from '@apollo/client';
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EmailNotValidatedAlert() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [resendRegisterConfirmEmail, { loading }] = useMutation(ResendRegisterConfirmEmailDocument, {
        onCompleted: () => {
            setDisabled(true);
            enqueueSnackbar(t('alert.validate-email.check'));
        },
        onError: (e) => enqueueSnackbar(t(e.message)),
    });
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (disabled) {
            setOpen(true);
        }
    };

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (disabled) {
            setTimeout(() => setDisabled(false), 60_000);
        }
    }, [disabled]);

    return (
        <Alert
            severity="warning"
            icon={<WarningAmberRounded />}
            sx={{
                border: 1,
                borderColor: 'warning.states-outlineBorder',
            }}
        >
            <AlertTitle sx={{ display: 'flex' }}>
                <Typography variant="subtitle1">{t('alert.warning.email.not-validated.title')}</Typography>
                <Tooltip open={open} onOpen={handleOpen} onClose={handleClose} title={t('str.please-wait')}>
                    <span style={{ margin: '0 0 0 auto' }}>
                        <LoadingButton
                            size="small"
                            color="inherit"
                            onClick={() => resendRegisterConfirmEmail()}
                            loading={loading}
                            disabled={disabled}
                        >
                            {t('str.action.progress.resend-email')}
                        </LoadingButton>
                    </span>
                </Tooltip>
            </AlertTitle>
            {t('alert.warning.email.not-validated.support-text')}
        </Alert>
    );
}
