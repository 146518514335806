import { createContext, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { COOKIES } from '@/constants';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';

const Context = createContext<{
    mainNavOpen: boolean;
    setMainNavOpen: (v: boolean, absolute?: boolean) => void;
    mainNavOpenAbsolute: boolean;
} | null>(null);

export function NavigationProvider({ children }: { children: React.ReactNode }) {
    const cookieOpened = Cookies.get(COOKIES.MAIN_NAV_OPENED);
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const [isMainNavOpen, setIsMainNavOpen] = useState<boolean>(
        isLargeScreen ? false : cookieOpened !== undefined ? cookieOpened === '1' : true,
    );
    const [mainNavOpenAbsolute, setMainNavOpenAbsolute] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        if (window.innerWidth <= theme.breakpoints.values.lg) {
            setIsMainNavOpen(false);
        }
    }, [theme.breakpoints.values.lg]);

    const setMainNavOpen = (open: boolean, absolute: boolean = false) => {
        setIsMainNavOpen(open);
        if ((open && absolute) || !open) {
            setMainNavOpenAbsolute(open);
        }
    };
    return (
        <Context.Provider value={{ mainNavOpen: isMainNavOpen, setMainNavOpen, mainNavOpenAbsolute }}>
            {children}
        </Context.Provider>
    );
}

export function useNavigation() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
