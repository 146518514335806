import { TeamPermission } from './graphql/generated/types.generated';

export const PATHS_NO_SUBSCRIPTION_REQUIRED = ['/settings', '/subscribe'];

export const TEAM_RESTRICTED_ROUTES = [
    {
        route: '/settings/workspace/billing',
        permissions: [TeamPermission.Billing],
    },
    {
        route: '/settings/workspace/general',
        permissions: [TeamPermission.Owner],
    },
];

export const COOKIES = {
    MAIN_NAV_OPENED: 'main-nav-opened',
};
