import { Menu } from '@/components/nav/menu/Menu';
import { TeamPermission } from '@/graphql/generated/types.generated';
import { hasRouteTeamPermission } from './has-route-team-permission';

/**
 * Fn responsible to remove menus which the current user doesn't have access
 * @param menu - Menu
 * @param teamPermissions - User permissions
 */
export const filterMenuTeamPermissions = (menu: Menu[], teamPermissions: TeamPermission[]) => {
    return menu.map((menu) => {
        return {
            ...menu,
            sub: menu.sub
                ?.map((sub0) => {
                    return {
                        ...sub0,
                        sub: sub0.sub
                            .map((sub1) => {
                                return {
                                    ...sub1,
                                    ...(!sub1.sub && {
                                        hasAccess: hasRouteTeamPermission(
                                            menu.path + sub1.path,
                                            teamPermissions,
                                        ),
                                    }),
                                    sub: sub1.sub
                                        ?.map((sub2) => {
                                            return {
                                                ...sub2,
                                                hasAccess: hasRouteTeamPermission(
                                                    menu.path + sub1.path + sub2.path,
                                                    teamPermissions,
                                                ),
                                            };
                                        })
                                        .filter((sub2) => sub2.hasAccess),
                                };
                            })
                            .filter((sub1) => sub1.hasAccess || (sub1.sub || []).length > 0),
                    };
                })
                .filter((menu) => menu.sub.length > 0),
        };
    });
};
