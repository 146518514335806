import { Box, Grid } from '@mui/material';

export default function InitialLoading() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Box component="img" src="/swonkie-logo.svg" alt="Swonkie Logo" width={70} />
        </Grid>
    );
}
