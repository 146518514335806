import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function MyBox({
    children,
    title,
    subtitle,
    divider,
    helpButton,
    disablePadding,
}: Readonly<{
    children: React.ReactNode;
    title?: string;
    subtitle?: string;
    divider?: boolean;
    helpButton?: React.ReactNode;
    disablePadding?: boolean;
}>) {
    const theme = useTheme();
    return (
        <Box
            border={1}
            borderRadius={3}
            borderColor="#C8C5CA"
            bgcolor="#FFF"
            p={theme.spacing(3, 3, 2)}
            maxWidth={theme.spacing(120)}
            {...(disablePadding && { p: 0 })}
        >
            <Grid container>
                <Grid item xs={helpButton ? 8 : 12}>
                    {title && <Typography variant="h5">{title}</Typography>}
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="flex-end">
                    {helpButton}
                </Grid>
                <Grid item xs={helpButton ? 8 : 12}>
                    {subtitle && (
                        <Typography variant="body2" color="text.secondary">
                            {subtitle}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            {divider && <Divider sx={{ my: 2 }} />}
            {children}
        </Box>
    );
}
