import { NotificationArea } from '@/graphql/generated/types.generated';

export const translationKeyNotificationArea = (area: NotificationArea) => {
    switch (area) {
        case NotificationArea.AdsAnalytics:
            return 'navigation.reports';
        case NotificationArea.Analytics:
            return 'navigation.reports';
        case NotificationArea.Calendar:
            return 'navigation.planning.calendar';
        case NotificationArea.CompetitorAnalytics:
            return 'navigation.reports.competitors';
        case NotificationArea.CompetitorsAlerts:
            return 'navigation.feeds.competitors';
        case NotificationArea.Drafts:
            return 'navigation.planning.drafts';
        case NotificationArea.Feed:
            return 'navigation.feeds.my-feed';
        case NotificationArea.General:
            return 'navigation.settings.workspace.general';
        case NotificationArea.Inbox:
            return 'navigation.inbox';
        case NotificationArea.Influencer:
            return 'navigation.influencers';
        case NotificationArea.ProduceContent:
            return 'navigation.compose';
        case NotificationArea.Profiles:
            return 'navigation.settings.workspace.social-profiles';
        case NotificationArea.Rss:
            return 'navigation.feeds.rss';
        case NotificationArea.TeamManagement:
            return 'navigation.settings.workspace.team';
        case NotificationArea.TeamWorkflow:
            return 'navigation.planning.staging';
        default:
            return 'navigation.settings.workspace.general';
    }
};
