import { useSocket } from '@/app/providers/socket-provider';
import { CountUnreadNotificationsDocument } from '@/graphql/generated/count-unread-notifications.generated';
import { useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { instantDownload } from '@/utils/instant-download';
import { NotificationButton, NotificationTab } from '@/graphql/generated/types.generated';
import { NotificationDocument, NotificationQuery } from '@/graphql/generated/notification.generated';

const NotificationsEmitControl = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [countNotifications] = useLazyQuery(CountUnreadNotificationsDocument, {
        fetchPolicy: 'network-only',
    });
    const [notification] = useLazyQuery(NotificationDocument);
    const { socket } = useSocket();
    const [notifications, setNotifications] = useState<NotificationQuery['notification'][]>([]);
    const [notify, setNotify] = useState(false);

    const handleNotifications = (notification: NotificationQuery['notification']) => {
        setNotifications((notifications) => {
            const clone = notifications.slice();

            const sameGroup = notifications.findIndex(
                (each) =>
                    (each.single || each.stacked)?.group ===
                    (notification.single || notification.stacked)?.group,
            );
            if (sameGroup !== -1) {
                clone.splice(sameGroup, 1);
            }

            clone.push(notification);

            return clone;
        });
    };

    useEffect(() => {
        if (socket) {
            socket.on('notification', async ({ notificationId }) => {
                setNotify(false);
                const { data } = await notification({ variables: { id: notificationId } });
                if (data?.notification) {
                    handleNotifications(data.notification);
                }
                await new Promise((resolve) => setTimeout(resolve, 5000));
                setNotify(true);
            });

            // Clean up the connection when the component is unmounted
            return () => {
                socket.off('notification');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket]);

    useEffect(() => {
        if (notify) {
            countNotifications();
            for (const notification of notifications) {
                enqueueSnackbar({
                    ...(notification.single && {
                        variant: 'singleNotification',
                        single: {
                            ...notification.single,
                            tab: NotificationTab.All,
                        },
                    }),
                    ...(notification.stacked && {
                        variant: 'stackedNotification',
                        stacked: notification.stacked,
                    }),
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });

                if (notification.single) {
                    const { actions } = notification.single;
                    if (actions) {
                        const [primaryButton, secondaryButton] = actions;
                        if (primaryButton?.type === NotificationButton.InstantDownload) {
                            instantDownload(primaryButton.data);
                        }

                        if (secondaryButton?.type === NotificationButton.InstantDownload) {
                            instantDownload(secondaryButton.data);
                        }
                    }
                }
            }
            setNotifications([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notify]);

    return null;
};

export default NotificationsEmitControl;
