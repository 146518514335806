import PageLayout from '@/components/PageLayout';
import { Suspense, useEffect, useState } from 'react';
import LegacyIframe from './LegacyIframe';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useNavigation } from '@/providers/navigation-provider';
import { drawerWidth, miniDrawerWidth } from '@/components/nav/MainNav';
import { matchPath, useLocation } from 'react-router-dom';
import OldLegacyIframe, { pathMatch as OldLegacyPaths } from './OldLegacyIframe';
import { pathMatch as LegacyPaths } from './LegacyIframe';

export type LegacyPath = { newPath: string; oldPath: string };

const LegacyIframes = () => {
    const { mainNavOpen, mainNavOpenAbsolute } = useNavigation();
    const [isLoading, setIsLoading] = useState(true);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const pathname = useLocation().pathname;
    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return null;
    }

    const isLegacy = LegacyPaths.some((paths) => {
        return (
            null != matchPath({ path: `/${paths.newPath}/*` }, pathname) ||
            null != matchPath({ path: `/${paths.oldPath}/*` }, pathname)
        );
    });
    const isOldLegacy = OldLegacyPaths.some((paths) => {
        return (
            null != matchPath({ path: `/${paths.newPath}/*` }, pathname) ||
            null != matchPath({ path: `/${paths.oldPath}/*` }, pathname)
        );
    });

    const showingBackdrop = showBackdrop && isOldLegacy;

    return (
        <>
            {showingBackdrop && (
                // Create Backdrop
                <Box
                    sx={{
                        background: 'rgba(0, 0, 0, 0.5)',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        width: '100vw',
                        height: '100vh',
                        zIndex: 1400,
                    }}
                ></Box>
            )}
            <Box
                id="OldLegacyIframeContainer"
                sx={{
                    ...(!isMobile && {
                        width:
                            'calc(100vw - ' +
                            (mainNavOpen && !mainNavOpenAbsolute ? drawerWidth : miniDrawerWidth) +
                            'px)',
                    }),
                    display: isLoading || !isOldLegacy ? 'none' : 'flex',
                    position: 'relative',
                }}
            >
                <PageLayout legacyIframe={true} boxMargin="0">
                    <Suspense fallback={null}>
                        <OldLegacyIframe showBackdrop={showingBackdrop} setShowBackdrop={setShowBackdrop} />
                    </Suspense>
                </PageLayout>
            </Box>
            <Box
                id="LegacyIframeContainer"
                sx={{
                    ...(!isMobile && {
                        width:
                            'calc(100vw - ' +
                            (mainNavOpen && !mainNavOpenAbsolute ? drawerWidth : miniDrawerWidth) +
                            'px)',
                    }),
                    display: isLoading || !isLegacy ? 'none' : 'flex',
                    position: 'relative',
                }}
            >
                <PageLayout legacyIframe={true} boxMargin="0">
                    <Suspense fallback={null}>
                        <LegacyIframe showBackdrop={showingBackdrop} setShowBackdrop={setShowBackdrop} />
                    </Suspense>
                </PageLayout>
            </Box>
        </>
    );
};

export default LegacyIframes;
