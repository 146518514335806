const colors = {
    amber: {
        50: '#fff8f4',
        100: '#ffddb6',
        200: '#ffb95b',
        300: '#e49d36',
        400: '#c5831b',
        500: '#a66a00',
        600: '#845400',
        700: '#643f00',
        800: '#462a00',
        900: '#2a1800',
        A100: '#fffbff',
        A200: '#ffeede',
        A400: '#744900',
        A700: '#553400',
    },
    blue: {
        50: '#fbf8ff',
        100: '#dfe0ff',
        200: '#bdc2ff',
        300: '#9aa4ff',
        400: '#7886ff',
        500: '#5365ff',
        600: '#283fff',
        700: '#0020dd',
        800: '#00149e',
        900: '#000965',
        A100: '#fffbff',
        A200: '#f1efff',
        A400: '#0829f9',
        A700: '#001abd',
    },
    blueGrey: {
        50: '#fbf8ff',
        100: '#e3e1ec',
        200: '#c7c5d0',
        300: '#abaab4',
        400: '#90909a',
        500: '#777680',
        600: '#5e5d67',
        700: '#46464f',
        800: '#2f3038',
        900: '#1a1b23',
        A100: '#fffbff',
        A200: '#f2effa',
        A400: '#52525b',
        A700: '#3b3b43',
    },
    cyan: {
        50: '#edfcff',
        100: '#97f0ff',
        200: '#4fd8eb',
        300: '#22bccf',
        400: '#00a0b0',
        500: '#008391',
        600: '#006874',
        700: '#004f58',
        800: '#00363d',
        900: '#001f24',
        A100: '#f6feff',
        A200: '#d0f8ff',
        A400: '#005b66',
        A700: '#00424a',
    },
    deepOrange: {
        50: '#fff8f6',
        100: '#ffdbd1',
        200: '#ffb5a0',
        300: '#ff8b69',
        400: '#ff5722',
        500: '#db3e03',
        600: '#b02f00',
        700: '#862200',
        800: '#5f1500',
        900: '#3b0900',
        A100: '#fffbff',
        A200: '#ffede8',
        A400: '#9b2800',
        A700: '#731b00',
    },
    deepPurple: {
        50: '#fef7ff',
        100: '#ebddff',
        200: '#d3bbff',
        300: '#bc99ff',
        400: '#a478f7',
        500: '#895edb',
        600: '#6f43c0',
        700: '#5727a6',
        800: '#3f008d',
        900: '#250059',
        A100: '#fffbff',
        A200: '#f7edff',
        A400: '#6336b3',
        A700: '#4b169b',
    },
    green: {
        50: '#e8ffef',
        100: '#89f8c1',
        200: '#6cdba6',
        300: '#4fbf8c',
        400: '#2da373',
        500: '#00885c',
        600: '#006c48',
        700: '#005235',
        800: '#003824',
        900: '#002113',
        A100: '#f4fff6',
        A200: '#bfffda',
        A400: '#005f3f',
        A700: '#00452c',
    },
    grey: {
        50: '#f3f0f4',
        100: '#e4e1e6',
        200: '#c8c5ca',
        300: '#acaaaf',
        400: '#929094',
        500: '#78767a',
        600: '#5f5e62',
        700: '#47464a',
        800: '#303034',
        900: '#1b1b1f',
        A100: '#fffbff',
        A200: '#f3f0f4',
        A400: '#535256',
        A700: '#3b3b3f',
    },
    indigo: {
        50: '#fbf8ff',
        100: '#e0e0ff',
        200: '#bec2ff',
        300: '#9ea4fd',
        400: '#8389e0',
        500: '#696fc4',
        600: '#5056a9',
        700: '#383e8f',
        800: '#202578',
        900: '#050865',
        A100: '#fffbff',
        A200: '#f1efff',
        A400: '#444a9c',
        A700: '#2c3183',
    },
    lightBlue: {
        50: '#f5faff',
        100: '#c5e7ff',
        200: '#7ed0ff',
        300: '#3fb6ef',
        400: '#019bd2',
        500: '#0080ae',
        600: '#00658b',
        700: '#004c6a',
        800: '#00344a',
        900: '#001e2d',
        A100: '#fbfcff',
        A200: '#e3f3ff',
        A400: '#00597a',
        A700: '#00405a',
    },
    lightGreen: {
        50: '#f1f8e9',
        100: '#dcedc8',
        200: '#c5e1a5',
        300: '#aed581',
        400: '#8bc34a',
        500: '#8bc34a',
        600: '#7cb342',
        700: '#689f38',
        800: '#558b2f',
        900: '#33691e',
        A100: '#ccff90',
        A200: '#b2ff59',
        A400: '#76ff03',
        A700: '#64dd17',
    },
    lime: {
        50: '#f0ffd7',
        100: '#b7f56b',
        200: '#9dd852',
        300: '#82bb39',
        400: '#69a01e',
        500: '#528400',
        600: '#406900',
        700: '#2f4f00',
        800: '#1f3700',
        900: '#102000',
        A100: '#f9ffe8',
        A200: '#d2ff9a',
        A400: '#385c00',
        A700: '#274300',
    },
    orange: {
        50: '#fff8f6',
        100: '#ffdbcc',
        200: '#ffb695',
        300: '#ff8c54',
        400: '#ec723d',
        500: '#c2571c',
        600: '#a23f00',
        700: '#7b2f00',
        800: '#571f00',
        900: '#351000',
        A100: '#fffbff',
        A200: '#ffede6',
        A400: '#8e3700',
        A700: '#692600',
    },
    pink: {
        50: '#fff8f7',
        100: '#ffd9e0',
        200: '#ffb1c3',
        300: '#ff85a6',
        400: '#ff4b89',
        500: '#e9006e',
        600: '#bb0057',
        700: '#8f0041',
        800: '#66002c',
        900: '#3f0019',
        A100: '#fff8f7',
        A200: '#ffecef',
        A400: '#a5004c',
        A700: '#7a0036',
    },
    purple: {
        50: '#fcf8ff',
        100: '#e4dfff',
        200: '#c6bfff',
        300: '#a9a0ff',
        400: '#8d7fff',
        500: '#715cff',
        600: '#5638f2',
        700: '#3d05db',
        800: '#2900a0',
        900: '#170066',
        A100: '#fffbff',
        A200: '#f3eeff',
        A400: '#4925e6',
        A700: '#3300be',
    },
    red: {
        50: '#fff8f7',
        100: '#ffdad6',
        200: '#ffb4ab',
        300: '#ff897d',
        400: '#ff5449',
        500: '#de3730',
        600: '#ba1a1a',
        700: '#93000a',
        800: '#690005',
        900: '#410002',
        A100: '#fffbff',
        A200: '#ffedea',
        A400: '#a80710',
        A700: '#7e0007',
    },
    teal: {
        50: '#e5feff',
        100: '#6ff6fc',
        200: '#4cd9df',
        300: '#1dbdc3',
        400: '#00a1a6',
        500: '#008489',
        600: '#00696d',
        700: '#004f52',
        800: '#003739',
        900: '#002021',
        A100: '#f1ffff',
        A200: '#bcfcff',
        A400: '#005c5f',
        A700: '#004345',
    },
    yellow: {
        50: '#fff8f4',
        100: '#ffddb6',
        200: '#ffb95a',
        300: '#e49d35',
        400: '#c5831a',
        500: '#a56a00',
        600: '#845400',
        700: '#744900',
        800: '#462a00',
        900: '#2a1800',
        A100: '#fffbff',
        A200: '#ffeedd',
        A400: '#744900',
        A700: '#553400',
    },
};

export default colors;
