import {
    HasFailedPaymentDocument,
    HasFailedPaymentQuery,
} from '@/graphql/generated/has-payment-failed.generated';
import { useQuery } from '@apollo/client';
import { createContext, useContext, useState } from 'react';

const Context = createContext<{
    hasFailedPayment: HasFailedPaymentQuery['hasFailedPayment'];
    onClose: () => void;
} | null>(null);

export function PaymentProvider({ children }: { children: React.ReactNode }) {
    const [hasFailedPayment, setHasFailedPayment] = useState(false);
    useQuery(HasFailedPaymentDocument, {
        fetchPolicy: 'network-only',
        onCompleted: ({ hasFailedPayment }) => {
            setHasFailedPayment(hasFailedPayment);
        },
    });

    const onClose = () => setHasFailedPayment(!hasFailedPayment);

    return <Context.Provider value={{ hasFailedPayment, onClose }}>{children}</Context.Provider>;
}

export function usePayment() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
