import { Badge, ListItemIcon, ListItemText, Theme, Typography, useMediaQuery } from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { useNavigation } from '@/providers/navigation-provider';
import { ListItemButton, menu } from './menu/Menu';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MainNavSettings({ showSubmenu }: { showSubmenu: () => void }) {
    const { t } = useTranslation();
    const { mainNavOpen } = useNavigation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const settingsMenu = menu.find((each) => each.path.startsWith('/settings')) || menu[0];
    const pathname = useLocation().pathname;

    return (
        <ListItemButton
            dense
            sx={{ borderRadius: '99px' }}
            {...(!mainNavOpen && { sx: { justifyContent: 'center' } })}
            href={isMobile ? '' : '/settings/personal/general'}
            onClick={showSubmenu}
            data-id={settingsMenu.dataId}
            selected={pathname.startsWith('/settings')}
        >
            <ListItemIcon
                sx={{
                    ...(!mainNavOpen && { minWidth: 0 }),
                }}
            >
                <Badge variant="dot" color="warning" invisible={/*mainNavOpen*/ true}>
                    <SettingsRoundedIcon />
                </Badge>
            </ListItemIcon>
            {mainNavOpen && (
                <>
                    <ListItemText>
                        <Typography noWrap variant="body2">
                            {t('navigation.settings')}
                        </Typography>
                    </ListItemText>
                    {/*<RelativeBadge variant="dot" color="warning" />*/}
                </>
            )}
        </ListItemButton>
    );
}
