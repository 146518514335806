import { useSession } from '@/providers/session-provider';
import React, { createContext, useContext, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const Context = createContext<{ socket: Socket | null } | null>(null);

export function SocketProvider({ children }: { children: React.ReactNode }) {
    const { me } = useSession();
    const [socket] = useState<Socket>(
        io(import.meta.env.VITE_SOCKETS_ROOT, {
            secure: true,
            transports: ['websocket'],
            auth: {
                token: me.socketToken,
            },
        }),
    );

    return <Context.Provider value={{ socket }}>{children}</Context.Provider>;
}

export function useSocket() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
