// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isset: boolean | any = (data: any | undefined, def: any | undefined) => {
    if (typeof def !== 'undefined') {
        return isset(data) ? data : def;
    }
    return !(typeof data === 'undefined');
};

const timeouts: UtilTimeouts = {};

interface UtilTimeouts {
    [key: string]: UtilTimeout;
}

export interface UtilTimeout {
    count: number;
    maxCount: number;
    obj: NodeJS.Timeout | null;
    clear: (this: UtilTimeout) => void;
}

export const timeout = (
    id: string | null = null,
    callback: (
        data?: Record<string, never> | object | null | undefined,
        timeout?: UtilTimeout | boolean,
    ) => void,
    time = 1000,
    data: Record<string, never> | null | undefined = {},
    repeat = false,
    maxRepeats = -1,
) => {
    if (id) {
        if (typeof timeouts[`${id}`] === 'undefined' || !timeouts[`${id}`].obj) {
            timeouts[`${id}`] = {
                count: 0,
                maxCount: maxRepeats,
                obj: repeat
                    ? setInterval(
                          (id, data) => {
                              if (
                                  timeouts[`${id}`].count >= timeouts[`${id}`].maxCount &&
                                  timeouts[`${id}`].maxCount >= 0
                              ) {
                                  timeouts[`${id}`].clear();
                              }
                              callback(data, timeouts[`${id}`]);
                              timeouts[`${id}`].count++;
                          },
                          time,
                          id,
                          data,
                      )
                    : setTimeout(
                          (id, data) => {
                              callback(data, timeouts[`${id}`]);
                              timeouts[`${id}`].clear();
                          },
                          time,
                          id,
                          data,
                      ),
                clear: repeat
                    ? function (this: UtilTimeout) {
                          if (this.obj != null) clearInterval(this.obj);
                          this.obj = null;
                      }
                    : function (this: UtilTimeout) {
                          if (this.obj != null) clearTimeout(this.obj);
                          this.obj = null;
                      },
            };
        }

        return timeouts[`${id}`];
    }

    return false;
};

export const getCookie = (name: string): string | null => {
    const cookieArr = document.cookie.split(';');

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[`${i}`].split('=');

        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return null;
};

export const setCookie = (name: string, value: string | number, daysToLive?: number, domain?: string) => {
    let cookie = name + '=' + encodeURIComponent(value);

    if (typeof daysToLive === 'number') {
        cookie += '; Path=/; max-age=' + daysToLive * 24 * 60 * 60;
    }
    if (typeof domain === 'string') {
        cookie += '; domain=' + domain;
        if (typeof daysToLive !== 'number') {
            cookie += '; Path=/';
        }
    }

    document.cookie = cookie;
};
