import { useEffect, useState } from 'react';
import { Box, Divider, List, Theme, useMediaQuery } from '@mui/material';
import Menu, { menu } from './menu/Menu';
import Submenu from './menu/Submenu';
import MainNavTrialCta from './MainNavTrialCta';
import MainNavNotifications from './MainNavNotifications';
import MainHelpResources from './MainNavHelpResources';
import MainNavSettings from './MainNavSettings';
import MainNavAccount from './MainNavAccount';
import { MainNavQuery } from '@/graphql/generated/main-nav.generated';
import { useNavigation } from '@/providers/navigation-provider';
import { useLocation } from 'react-router-dom';

export default function MainNavMenu({
    currentWorkspace,
    currentSubscription,
}: {
    currentWorkspace?: MainNavQuery['currentWorkspace'];
    currentSubscription?: MainNavQuery['subscriptionPreview'];
}) {
    const pathname = useLocation().pathname;
    const [mainDrawer, setMainDrawer] = useState(true);
    const [menuSelected, setMenuSelected] = useState(
        menu.find(({ path }) => pathname.startsWith(path))?.path || menu[0].path,
    );
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { mainNavOpen } = useNavigation();

    const showSubmenu = (menu: string) => {
        if (isMobile) {
            setMainDrawer(false);
        }
        setMenuSelected(menu);
    };

    useEffect(() => {
        if (isMobile) {
            const currMenu = menu.find(({ path }) => path === menuSelected);
            if (currMenu?.sub) {
                setMainDrawer(false);
            }
        }
    }, [isMobile, menuSelected]);

    return (
        <>
            <List>
                {mainDrawer && <Menu showSubmenu={showSubmenu} />}
                {!mainDrawer && (
                    <Submenu mobile menuSelected={menuSelected} backToMenu={() => setMainDrawer(true)} />
                )}
            </List>

            {mainDrawer && (
                <Box sx={{ mt: 'auto' }}>
                    {currentSubscription?.plan?.isTrial && (
                        <MainNavTrialCta subscriptionExpireDate={currentSubscription.expiresAt} />
                    )}
                    <Divider sx={{ py: '0.5rem', ...(!mainNavOpen && { display: 'none' }) }} />
                    <List sx={{ ...(!mainNavOpen && { px: 1 }) }}>
                        <MainNavNotifications />
                        <MainHelpResources />
                        <MainNavSettings showSubmenu={() => showSubmenu('/settings')} />
                        <MainNavAccount currentWorkspace={currentWorkspace} />
                    </List>
                </Box>
            )}
        </>
    );
}
