import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY!, {
        api_host: import.meta.env.VITE_POSTHOG_HOST,
        capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
