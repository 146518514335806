import { Box, Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function TeamAccessDenied() {
    const { t } = useTranslation();

    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <Stack alignItems="center" maxWidth={924} textAlign="center">
                <Box component="img" src="/prohibited_3d.webp" alt="" height={256} width={256} />
                <Typography variant="h1" mt={1.25}>
                    {t('error.permissions.title')}
                </Typography>
                <Typography variant="h5" mt={2}>
                    {t('error.permissions.subtitle')}
                </Typography>
                <Typography color="text.secondary" mt={2}>
                    {t('error.permissions.support-text')}
                </Typography>

                <Button size="large" variant="contained" sx={{ mt: 3 }} href="/">
                    {t('error.permissions.action.return')}
                </Button>

                <Typography variant="caption" color="text.secondary" mt={6}>
                    <Trans
                        i18nKey={'error.permissions.help-text'}
                        components={{
                            email: (
                                <Typography
                                    component={Link}
                                    to="mailto:sayhello@swonkie.com"
                                    variant="caption"
                                    color="primary.main"
                                    display="inline-block"
                                    sx={{ textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
}
