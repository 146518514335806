import { InboxRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const AllCaughtUp = () => {
    const { t } = useTranslation();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ height: 'calc(100% - 100px)', overflow: 'auto' }}
        >
            <Stack gap={2} alignItems="center" textAlign="center">
                <InboxRounded sx={{ height: 93, width: 93 }} color="disabled" />
                <Stack>
                    <Typography variant="h5" color="text.secondary">
                        {t('notifications.modal.empty-state.title')}
                    </Typography>
                    <Typography variant="body2" color="text.disabled">
                        {t('notifications.modal.empty-state.support-text')}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
