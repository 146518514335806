import {
    Container,
    ContainerProps,
    Divider,
    IconButton,
    LinearProgress,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Skeleton,
} from '@mui/material';
import { useEffect } from 'react';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { useSession } from '@/providers/session-provider';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { MainNavQuery } from '@/graphql/generated/main-nav.generated';
import { LogoutDocument } from '@/graphql/generated/logout.generated';
import { useSnackbar } from 'notistack';
import { WorkspacesResumeDocument } from '@/graphql/generated/workspaces-resumes.generated';
import { useGlobalLoading } from '@/providers/global-loading-provider';
import { SwitchWorkspaceDocument } from '@/graphql/generated/switch-workspace.generated';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import WorkspaceAvatar from './workspace/WorkspaceAvatar';
import { useTranslation } from 'react-i18next';
import { sendMessage as sendMessageLegacy } from '@/legacy/components/LegacyIframe';
import { sendMessage as sendMessageOldLegacy } from '@/legacy/components/OldLegacyIframe';

const StyledContainer = styled(Container)<ContainerProps>(() => ({
    '&.MuiContainer-root': {
        padding: 0,
    },
}));
/*
function MoreButton() {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuList>
                    <MenuItem>
                        <ListItemIcon>
                            <AddCircleOutlineRoundedIcon />
                        </ListItemIcon>
                        <ListItemText>Join or create workspace</ListItemText>
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon>
                            <LogoutRoundedIcon />
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>
            <IconButton onClick={handleClick}>
                <MoreHorizOutlinedIcon />
            </IconButton>
        </>
    );
}*/

const LoadWorkspacesSkeleton = () => {
    return (
        <MenuItem dense sx={{ padding: '0.375rem' }}>
            <ListItemIcon>
                <IconButton>
                    <DragIndicatorRoundedIcon />
                </IconButton>
            </ListItemIcon>
            <ListItemAvatar>
                <Skeleton variant="rounded" sx={{ width: '2rem', height: '2rem' }} animation="wave" />
            </ListItemAvatar>
            <ListItemText
                primary={<Skeleton variant="text" animation="wave" width={'50%'} />}
                secondary={<Skeleton variant="text" animation="wave" width={'50%'} />}
                secondaryTypographyProps={{ variant: 'caption' }}
            />
        </MenuItem>
    );
};

export default function WorkspaceSwitcher({
    popOverLoadingComplete,
    currentWorkspace,
}: {
    popOverLoadingComplete?: () => void;
    currentWorkspace?: MainNavQuery['currentWorkspace'];
}) {
    const { t } = useTranslation();
    const { me } = useSession();
    const { enqueueSnackbar } = useSnackbar();
    const { setGlobalLoading } = useGlobalLoading();
    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const {
        data,
        loading: loadingWorkspaces,
        error,
        fetchMore,
    } = useQuery(WorkspacesResumeDocument, {
        notifyOnNetworkStatusChange: true,
    });
    const [logoutMutation, { loading: loadingLogout }] = useMutation(LogoutDocument);
    const [switchWorkspaceMutation, { loading: loadingSwitchWorkspace }] =
        useMutation(SwitchWorkspaceDocument);

    useEffect(() => {
        if (popOverLoadingComplete) {
            popOverLoadingComplete();
        }
    }, [loadingWorkspaces, popOverLoadingComplete, data]);

    useEffect(() => {
        setGlobalLoading(loadingSwitchWorkspace);
    }, [loadingSwitchWorkspace, setGlobalLoading]);

    const loadMore = () => {
        fetchMore({ variables: { skip: data?.workspaces.items?.length } });
    };

    const logout = () => {
        logoutMutation({
            onCompleted: (data) => {
                if (data.logout) {
                    window.location.href = `${import.meta.env.VITE_HOMEPAGE_ROOT}/logout`;
                } else {
                    enqueueSnackbar(t('error.something-wrong'));
                }
            },
            onError: (e) => enqueueSnackbar(t(e.message)),
        });
    };

    const switchWorkspace = (id: string) => {
        switchWorkspaceMutation({
            variables: { id },
            onCompleted: () => {
                navigate('/');
                apolloClient.resetStore().then(() => {
                    // reload iframes
                    sendMessageLegacy({ type: 'reload' });
                    sendMessageOldLegacy({ type: 'reload' });
                });
            },
            onError: (e) => enqueueSnackbar(t(e.message)),
        });
    };

    return (
        <MenuList sx={{ width: '100%' }}>
            <StyledContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
                <MenuItem dense>
                    <ListItemText>{me.email}</ListItemText>
                    {/*<ListItemIcon>
                        <MoreButton />
                    </ListItemIcon>*/}
                </MenuItem>
                {data?.workspaces?.items?.map((workspace) => (
                    <MenuItem
                        key={workspace.id}
                        dense
                        sx={{ padding: '0.375rem' }}
                        onClick={() => switchWorkspace(workspace.id)}
                    >
                        <ListItemIcon>
                            <IconButton>
                                <DragIndicatorRoundedIcon />
                            </IconButton>
                        </ListItemIcon>
                        <ListItemAvatar>
                            <WorkspaceAvatar
                                {...workspace}
                                sx={(theme) => ({
                                    width: theme.spacing(4),
                                    height: theme.spacing(4),
                                    fontSize: theme.spacing(1.5),
                                })}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={workspace.name}
                            secondary={`${workspace.plan?.name} • ${workspace.totalMembers} members`}
                            secondaryTypographyProps={{ variant: 'caption' }}
                        />

                        <ListItemSecondaryAction sx={{ right: '0.5rem' }}>
                            {workspace.id === currentWorkspace?.id && <DoneRoundedIcon color="primary" />}
                            {/*<RelativeBadge badgeContent="100" color="error" />*/}
                        </ListItemSecondaryAction>
                    </MenuItem>
                ))}

                {(loadingWorkspaces || error) && <LoadWorkspacesSkeleton />}

                {data?.workspaces.hasMore && !loadingWorkspaces && (
                    <MenuItem dense onClick={loadMore}>
                        <ListItemText>{t('str.load-more')}</ListItemText>
                    </MenuItem>
                )}
                <Divider />
            </StyledContainer>
            {/*<MenuItem dense>
                <ListItemText>Add another account</ListItemText>
            </MenuItem>*/}
            <MenuItem dense onClick={logout}>
                <ListItemText>{loadingLogout ? <LinearProgress /> : 'Log out'}</ListItemText>
            </MenuItem>
        </MenuList>
    );
}
