import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_COOKIE, getOptions } from './settings';
import dayjs from 'dayjs';

i18next.on('languageChanged', function (lng) {
    dayjs.locale(lng);
});

// Initialize i18next for the client side
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(resourcesToBackend((lang: string, ns: string) => import(`./locales/${lang}/${ns}.json`)))
    .init({
        ...getOptions(),
        detection: {
            // order and from where user language should be detected
            order: ['cookie', 'navigator'],

            // keys or params to lookup language from
            lookupCookie: LANGUAGE_COOKIE,

            // This will automatically update the cookie
            caches: ['cookie'],
        },
        ns: ['translation', 'languages', 'countries'],

        // print missing keys
        //saveMissing: true,
        //missingKeyHandler: function (lng, ns, key) {
        //    console.log(key);
        //},
    });

export default i18next;
