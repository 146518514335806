import { Workspace } from '@/graphql/generated/types.generated';
import { stringAvatar } from '@/utils/string-avatar';
import { Avatar, AvatarProps } from '@mui/material';

type WorkspaceAvatarProps = Partial<Pick<Workspace, 'name' | 'color' | 'picture'>> & AvatarProps;
const WorkspaceAvatar = ({ name, color, picture, ...props }: WorkspaceAvatarProps) => {
    return (
        <Avatar
            src={picture || undefined}
            variant="rounded"
            style={{ backgroundColor: color, textTransform: 'uppercase' }}
            {...props}
        >
            {stringAvatar(name)}
        </Avatar>
    );
};

export default WorkspaceAvatar;
