import { Box, List, Theme, Toolbar, useMediaQuery } from '@mui/material';
import AppBar from '@/components/AppBar';
import { menu } from './nav/menu/Menu';
import Submenu from './nav/menu/Submenu';
import PaymentFailedAlert from './PaymentFailedAlert';
import { usePayment } from '@/providers/payment-provider';
import { submenuWidth } from './nav/MainNav';
import EmailNotValidatedAlert from './EmailNotValidatedAlert';
import { useQuery } from '@apollo/client';
import { HasEmailValidatedDocument } from '@/graphql/generated/has-email-validated.generated';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const PageLayout = ({
    children,
    boxMargin,
    legacyIframe,
}: Readonly<{
    children: React.ReactNode;
    boxMargin?: string;
    legacyIframe?: boolean;
}>) => {
    const pathname = useLocation().pathname;
    const submenu = menu.find((each) => pathname.startsWith(each.path));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { hasFailedPayment, onClose } = usePayment();
    const theme = useTheme();
    const { data } = useQuery(HasEmailValidatedDocument);

    const hasEmailValidated = !data || data.hasEmailValidated;

    return (
        <Box width="100vw" minHeight="100vh" height="100%" bgcolor="#F3F4FE">
            <AppBar />
            <Toolbar variant="dense" />
            <Box display="flex">
                {!isMobile && submenu?.sub && (
                    <Box
                        width="100%"
                        maxWidth={submenuWidth}
                        minHeight="calc(100vh - 3rem)"
                        bgcolor="#FFF"
                        borderRight="1px solid #C8C5CA"
                        px={1}
                        position="fixed"
                    >
                        <List>
                            <Submenu menuSelected={submenu.path} />
                        </List>
                    </Box>
                )}

                <Box
                    component="main"
                    m={boxMargin || 3}
                    flexGrow={1}
                    {...(!isMobile &&
                        submenu?.sub && {
                            ml: legacyIframe
                                ? `${submenuWidth}px`
                                : `calc(${theme.spacing(3)} + ${submenuWidth}px)`,
                        })}
                    {...(legacyIframe && {
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100vh - 3rem - 1px)',
                        mt: '1px',
                    })}
                >
                    {hasFailedPayment ? (
                        <Box
                            mb={3}
                            {...(legacyIframe && {
                                m: 3,
                            })}
                        >
                            <PaymentFailedAlert onClose={onClose} />
                        </Box>
                    ) : !hasEmailValidated ? (
                        <Box
                            mb={3}
                            {...(legacyIframe && {
                                m: 3,
                            })}
                        >
                            <EmailNotValidatedAlert />
                        </Box>
                    ) : null}
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default PageLayout;
