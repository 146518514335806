export const instantDownload = (data: { url?: string; name?: string }) => {
    const { url = '', name = '' } = data;
    const el = document.createElement('a');
    el.style.display = 'none';
    el.target = '_blank';
    el.href = url;
    el.download = name;
    el.click();
    el.remove();
};
