import { useNavigation } from '@/providers/navigation-provider';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import {
    Collapse,
    List,
    ListItemButton as MuiListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    useMediaQuery,
    ListItemIcon,
    ListItem,
    Divider,
    Box,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { ListItemButton, menu } from './Menu';
import { filterMenuTeamPermissions } from '@/utils/permissions/filter-menu-team-permissions';
import { useTeamPermissions } from '@/providers/team-permissions-provider';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const SubListItemMenu = styled(MuiListItemButton)(({ theme }) => ({
    borderRadius: '99px',
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        svg: {
            color: theme.palette.primary.main,
        },
    },
}));

const SubMenuCollapse = ({
    menuOpen,
    name,
    path,
    sub,
}: {
    menuOpen: string;
    name: string;
    path: string;
    sub: { name: string; path: string; dataId?: string }[];
}) => {
    const pathname = useLocation().pathname;
    const [open, setOpen] = useState(pathname.startsWith(`${menuOpen}${path}`));
    const { setMainNavOpen } = useNavigation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Fragment>
            <SubListItemMenu
                dense
                selected={pathname.startsWith(`${menuOpen}${path}`)}
                onClick={() => setOpen(!open)}
            >
                <ListItemText primary={name} primaryTypographyProps={{ variant: 'body2' }} />
                <ListItemSecondaryAction sx={{ height: '1.5rem' }}>
                    {open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                </ListItemSecondaryAction>
            </SubListItemMenu>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List>
                    {sub.map((each) => (
                        <ListItemButton
                            key={each.path}
                            href={`${menuOpen}${path}${each.path}`}
                            dense
                            selected={pathname.startsWith(`${menuOpen}${path}${each.path}`)}
                            sx={{ pl: '1.5rem' }}
                            onClick={() => {
                                if (isMobile) {
                                    setMainNavOpen(false);
                                }
                            }}
                            data-id={each.dataId}
                        >
                            <ListItemText
                                primary={`SUB-${each.name}`}
                                primaryTypographyProps={{ variant: 'body2' }}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default function Submenu({
    mobile = false,
    menuSelected,
    backToMenu,
}: {
    mobile?: boolean;
    menuSelected: string;
    backToMenu?: () => void;
}) {
    const { t } = useTranslation();
    const pathname = useLocation().pathname;
    const { teamPermissions } = useTeamPermissions();

    const menuFiltered = filterMenuTeamPermissions(menu, teamPermissions);
    const currMenu = menuFiltered.find(({ path }) => path === menuSelected);

    const { setMainNavOpen } = useNavigation();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box visibility={mobile === isMobile ? 'visible' : 'hidden'}>
            {isMobile && (
                <ListItemButton onClick={backToMenu}>
                    <ListItemIcon>
                        <ArrowBackRounded />
                    </ListItemIcon>
                    <ListItemText primary={t('navigation.main-menu')} />
                </ListItemButton>
            )}
            {currMenu?.sub?.map((sub) => (
                <Fragment key={sub.subheader}>
                    {sub.subheader && (
                        <ListItem dense={false}>
                            <ListItemText
                                primary={t(sub.subheader)}
                                primaryTypographyProps={{ variant: 'subtitle2', color: 'text.disabled' }}
                            />
                        </ListItem>
                    )}
                    {sub.sub?.map((sub) => {
                        const isSelected = pathname.startsWith(currMenu.path + sub.path);
                        return (
                            <Fragment key={sub.path}>
                                {sub.sub ? (
                                    <SubMenuCollapse
                                        data-id={sub.dataId}
                                        menuOpen={currMenu?.path}
                                        {...sub}
                                        sub={sub.sub}
                                    />
                                ) : (
                                    <ListItemButton
                                        href={`${currMenu.path}${sub.path}`}
                                        dense
                                        selected={isSelected}
                                        onClick={() => {
                                            if (isMobile) {
                                                setMainNavOpen(false);
                                            }
                                        }}
                                        data-id={sub.dataId}
                                    >
                                        <ListItemText
                                            primary={t(sub.name)}
                                            primaryTypographyProps={{ variant: 'body2' }}
                                        />
                                    </ListItemButton>
                                )}
                            </Fragment>
                        );
                    })}
                    <Divider
                        sx={{ mt: (theme) => theme.spacing(1), ':last-of-type': { visibility: 'hidden' } }}
                    />
                </Fragment>
            ))}
        </Box>
    );
}
