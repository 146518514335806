import { IconButton } from '@mui/material';
import { closeSnackbar, SnackbarProvider as SnackbarProviderNotistack } from 'notistack';
import Close from '@mui/icons-material/Close';
import {
    SingleNotificationProps,
    SingleNotificationToaster,
} from '@/components/notifications/SingleNotification';
import {
    StackedNotificationProps,
    StackedNotificationToaster,
} from '@/components/notifications/StackedNotification';

declare module 'notistack' {
    interface VariantOverrides {
        singleNotification: {
            single?: SingleNotificationProps;
        };
        stackedNotification: {
            stacked?: StackedNotificationProps;
        };
    }
}

// Created this because nextjs doesn't play well with notistack, it was requiring SnackbarProvider to have 'use client'

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
    return (
        <SnackbarProviderNotistack
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            maxSnack={6}
            autoHideDuration={4000}
            action={(snackbarId) => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <Close />
                </IconButton>
            )}
            Components={{
                singleNotification: SingleNotificationToaster,
                stackedNotification: StackedNotificationToaster,
            }}
        >
            {children}
        </SnackbarProviderNotistack>
    );
}
