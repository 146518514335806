import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

const ActionButton = styled(Button)<LinkProps>(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: 'max-content',
    },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
    },
}));

export default function NotFound() {
    const { t } = useTranslation();

    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <ResponsiveStack direction="row" gap={7.5} alignItems="center" maxWidth={924}>
                <Stack gap={4.5}>
                    <Typography variant="h1" color="text.primary">
                        {t('error.page.404.title')}
                    </Typography>
                    <Stack gap={2}>
                        <Typography variant="h5" color="text.primary" whiteSpace={'pre-line'}>
                            {t('error.page.404.support-text')}
                        </Typography>
                    </Stack>
                    <ActionButton size="large" variant="outlined" to={`/`} LinkComponent={Link}>
                        {t('error.page.404.action.back-to-swonkie')}
                    </ActionButton>
                    <Typography variant="caption" color="text.secondary">
                        <Trans
                            i18nKey={'error.page.404.helper-text'}
                            components={{
                                email: (
                                    <Typography
                                        component={Link}
                                        to="mailto:sayhello@swonkie.com"
                                        variant="caption"
                                        color="primary.main"
                                        display="inline-block"
                                        sx={{ textDecoration: 'none' }}
                                    />
                                ),
                            }}
                        />
                    </Typography>
                </Stack>
                <Box component="img" src="/404.webp" alt="" height={256} width={256} />
            </ResponsiveStack>
        </Stack>
    );
}
