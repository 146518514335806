import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from '@/libs/apollo-client';
import { Box } from '@mui/material';
import getInitColorSchemeScript from '@mui/system/cssVars/getInitColorSchemeScript';
import { GlobalLoadingProvider } from '@/providers/global-loading-provider';
import { NavigationProvider } from '@/providers/navigation-provider';
import { PHProvider } from '@/providers/posthog-provider';
import PostHogPageView from '@/PostHogPageView';
import { SnackbarProvider } from './providers/notistack-provider';
import AppRoutes from './AppRoutes';

function App() {
    return (
        <>
            <BrowserRouter>
                <PHProvider>
                    <PostHogPageView />
                    <ApolloClient>
                        <GlobalLoadingProvider>
                            <NavigationProvider>
                                <SnackbarProvider>
                                    <Box sx={{ display: 'flex' }}>
                                        {getInitColorSchemeScript()}
                                        <AppRoutes />
                                    </Box>
                                </SnackbarProvider>
                            </NavigationProvider>
                        </GlobalLoadingProvider>
                    </ApolloClient>
                </PHProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
