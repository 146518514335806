import { PATHS_NO_SUBSCRIPTION_REQUIRED } from '@/constants';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SubscriptionAccessControl({
    hasActiveSubscription,
}: {
    hasActiveSubscription: boolean;
}) {
    const pathname = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(() => {
        const routeAllowed = PATHS_NO_SUBSCRIPTION_REQUIRED.find((path) => pathname.startsWith(path));
        if (!routeAllowed && !hasActiveSubscription) {
            navigate('/settings/subscription-expired');
        }
    }, [hasActiveSubscription, navigate, pathname]);

    return null;
}
