import { AppInitialLoadQuery } from '@/graphql/generated/app-initial-load.generated';
import {
    TeamPermissionsDocument,
    TeamPermissionsQuery,
} from '@/graphql/generated/team-permissions.generated';
import { useLazyQuery } from '@apollo/client';
import { createContext, useContext, useEffect, useState } from 'react';

const Context = createContext<{
    teamPermissions: TeamPermissionsQuery['teamPermissions'];
    loading: boolean;
    refetch: () => void;
} | null>(null);

export function TeamPermissionsProvider({
    initialValue,
    children,
}: {
    initialValue: AppInitialLoadQuery;
    children: React.ReactNode;
}) {
    const [permissions, setPermissions] = useState(initialValue.teamPermissions);
    const [getPermissions, { loading }] = useLazyQuery(TeamPermissionsDocument, {
        fetchPolicy: 'network-only',
    });

    // update context when initial query updates (ex.: when switching workspaces)
    useEffect(() => {
        setPermissions(initialValue.teamPermissions);
    }, [initialValue.teamPermissions]);

    const fetch = () => {
        getPermissions().then((data) => {
            if (data.data?.teamPermissions) {
                setPermissions(data.data.teamPermissions);
            } else {
                throw new Error();
            }
        });
    };

    return (
        <Context.Provider value={{ teamPermissions: permissions, refetch: fetch, loading }}>
            {children}
        </Context.Provider>
    );
}

export function useTeamPermissions() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
