import { Box, Button, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

export default function ErrorBoundary() {
    const { t } = useTranslation();
    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <Stack direction="column" alignItems="center" justifyContent="center" maxWidth={924}>
                <Box component="img" src="/error.svg" alt="" height={256} width={256} />
                <Typography variant="h2" color="text.primary" mt={2}>
                    {t('error.page.something-wrong.title')}
                </Typography>
                <Typography variant="h5" color="text.primary" mt={2}>
                    {t('error.page.something-wrong.subtitle')}
                </Typography>
                <Button size="large" variant="contained" href={'/'} sx={{ my: 5.25 }}>
                    {t('error.page.something-wrong.action.button.go-home')}
                </Button>
                <Typography variant="caption" color="text.secondary">
                    <Trans
                        i18nKey={'error.page.something-wrong.helper-text'}
                        components={{
                            email: (
                                <Typography
                                    component="a"
                                    href="mailto:sayhello@swonkie.com"
                                    variant="caption"
                                    color="primary.main"
                                    display="inline-block"
                                    sx={{ textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
}
