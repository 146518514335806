import { useNavigation } from '@/providers/navigation-provider';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import CampaignRounded from '@mui/icons-material/CampaignRounded';
import CreateRounded from '@mui/icons-material/CreateRounded';
import DateRangeRounded from '@mui/icons-material/DateRangeRounded';
import HomeRounded from '@mui/icons-material/HomeRounded';
import InboxRounded from '@mui/icons-material/InboxRounded';
import ListAltRounded from '@mui/icons-material/ListAltRounded';
import SettingsRounded from '@mui/icons-material/SettingsRounded';
import {
    Box,
    ListItemButton as MuiListItemButton,
    ListItemIcon,
    ListItemText,
    Fab as MuiFab,
    Theme,
    useMediaQuery,
    Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export type Menu = {
    name: string;
    icon: ReactNode;
    path: string;
    dataId?: string;
    sub?: Submenu[];
    secondary?: boolean;
};

type Submenu = {
    subheader?: string;
    sub: {
        name: string;
        path: string;
        dataId?: string;
        sub?: { name: string; dataId?: string; path: string }[];
    }[];
};

export const menu: Menu[] = [
    {
        name: 'navigation.home',
        icon: <HomeRounded />,
        path: '/dashboard',
    },
    {
        name: 'navigation.planning',
        icon: <DateRangeRounded />,
        path: '/planning',
        sub: [
            {
                subheader: 'navigation.planning',
                sub: [{ name: 'navigation.planning.calendar', path: '/calendar' }],
            },
            {
                subheader: 'navigation.planning.workflow',
                sub: [
                    {
                        name: 'navigation.planning.approval-status',
                        path: '/posts-workflow',
                    },
                    {
                        name: 'navigation.planning.staging',
                        path: '/workflow-pool',
                    },
                ],
            },
            {
                subheader: 'navigation.planning.content',
                sub: [
                    {
                        name: 'navigation.planning.drafts',
                        path: '/drafts',
                    },
                ],
            },
        ],
    },
    {
        name: 'navigation.reports',
        icon: <BarChartRounded />,
        path: '/reports',
        sub: [
            {
                subheader: 'navigation.reports.personal',
                sub: [
                    { name: 'navigation.reports.basic', path: '/basic' },
                    { name: 'navigation.reports.advanced', path: '/advanced' },
                    { name: 'navigation.reports.competitors', path: '/competitors' },
                ],
            },
            {
                subheader: 'navigation.reports.paid-media',
                sub: [
                    { name: 'navigation.reports.ads-overview', path: '/ads-overview' },
                    {
                        name: 'navigation.reports.campaign-performance',
                        path: '/ads-campaigns',
                    },
                ],
            },
        ],
    },
    {
        name: 'navigation.feeds',
        icon: <ListAltRounded />,
        path: '/feeds',
        sub: [
            {
                subheader: 'navigation.feeds',
                sub: [
                    { name: 'navigation.feeds.my-feed', path: '/posts' },
                    {
                        name: 'navigation.feeds.competitors',
                        path: '/competitor-monitoring',
                    },
                    { name: 'navigation.feeds.rss', path: '/rss-feed' },
                ],
            },
            {
                subheader: 'navigation.feeds.records',
                sub: [{ name: 'navigation.feeds.publishing-record', path: '/my-posts' }],
            },
        ],
    },
    { name: 'navigation.inbox', icon: <InboxRounded />, path: '/inbox' },
    { name: 'navigation.listening', icon: <CampaignRounded />, path: '/listening' },
    {
        name: 'navigation.settings',
        icon: <SettingsRounded />,
        path: '/settings',
        dataId: 'menu-settings',
        secondary: true,
        sub: [
            {
                subheader: 'navigation.settings.personal',
                sub: [
                    {
                        name: 'navigation.settings.personal.general',
                        path: '/personal/general',
                    },
                    {
                        name: 'navigation.settings.personal.security',
                        path: '/personal/security',
                    },
                    {
                        name: 'navigation.settings.personal.privacy',
                        path: '/personal/privacy',
                    },
                ],
            },
            {
                subheader: 'navigation.settings.workspace',
                sub: [
                    {
                        name: 'navigation.settings.workspace.general',
                        path: '/workspace/general',
                    },
                    {
                        name: 'navigation.settings.workspace.account-status',
                        path: '/workspace/account-status',
                    },
                    {
                        name: 'navigation.settings.workspace.social-profiles',
                        path: '/workspace/social-profile',
                    },
                    {
                        name: 'navigation.settings.workspace.groups',
                        path: '/workspace/groups',
                    },
                    {
                        name: 'navigation.settings.workspace.team',
                        path: '/workspace/team',
                    },
                    {
                        name: 'navigation.settings.workspace.billing',
                        path: '/workspace/billing',
                    },
                    {
                        name: 'navigation.settings.workspace.integrations',
                        path: '/workspace/integrations',
                    },
                    {
                        name: 'navigation.settings.workspace.rss',
                        path: '/workspace/rss-content',
                    },
                    {
                        name: 'navigation.settings.workspace.website-api',
                        path: '/workspace/api-keys',
                    },
                ],
            },
        ],
    },
];

const Fab = styled(MuiFab)<LinkProps>(({ theme, variant }) => ({
    borderRadius: theme.spacing(0.5),
    fontSize: '0.75rem',
    textTransform: 'none',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    flexGrow: 1,
    ...(variant === 'circular' && { maxWidth: '2.5rem' }),
}));

export const ListItemButton = styled(MuiListItemButton)<{ href?: LinkProps['to'] }>(({ theme }) => ({
    borderRadius: '99px',
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        svg: {
            color: theme.palette.primary.main,
        },
    },
}));

export const getMenuPath = (menu: Menu) => {
    let menuPath = menu.path;
    if (menu.sub) {
        if (menu.sub[0].sub) {
            menuPath += menu.sub[0].sub[0].path;
        }
    }
    return menuPath;
};

export default function Menu({ showSubmenu }: { showSubmenu: (menu: string) => void }) {
    const { mainNavOpen, setMainNavOpen } = useNavigation();
    const pathname = useLocation().pathname;
    const { t } = useTranslation();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box py={1}>
            <Box display="flex" data-id="menu-produce-content" justifyContent="center" mb={3}>
                <Fab
                    variant={mainNavOpen ? 'extended' : 'circular'}
                    color={'primary'}
                    size="small"
                    sx={{ gap: 1, m: 0, p: 1, borderRadius: '8px', boxShadow: 'none' }}
                    to="/produce-content"
                    LinkComponent={Link}
                >
                    {mainNavOpen && t('navigation.compose')}
                    <CreateRounded />
                </Fab>
            </Box>

            <Box display="flex" flexDirection="column" {...(!mainNavOpen && { sx: { px: 1 } })}>
                {menu
                    .filter((item) => !item.secondary)
                    .map((item) => (
                        <ListItemButton
                            key={item.path}
                            dense
                            href={item.sub && isMobile ? '' : getMenuPath(item)}
                            onClick={() => {
                                if (item.sub) {
                                    showSubmenu(item.path);
                                } else if (isMobile) {
                                    setMainNavOpen(false);
                                }
                            }}
                            selected={pathname.startsWith(item.path)}
                            sx={{ ...(!mainNavOpen && { justifyContent: 'center', py: 1 }) }}
                        >
                            <ListItemIcon sx={{ ...(!mainNavOpen && { minWidth: 0 }) }}>
                                {item.icon}
                            </ListItemIcon>
                            {mainNavOpen && (
                                <ListItemText>
                                    <Typography noWrap variant="body2">
                                        {t(item.name)}
                                    </Typography>
                                </ListItemText>
                            )}
                        </ListItemButton>
                    ))}
            </Box>
        </Box>
    );
}
