import { useGlobalLoading } from '@/providers/global-loading-provider';
import { ConfirmUserChangeEmailDocument } from '@/graphql/generated/user-email-change.generated';
import { useMutation } from '@apollo/client';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';

const ActionButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: 'max-content',
    },
}));

const ResponsiveStack = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
    },
}));

const SuccessMessage = () => {
    return (
        <>
            <Stack gap={4.5}>
                <Typography variant="h1" color="primary.dark">
                    {t('email.page.verification.confirmed.title')}
                </Typography>
                <Stack gap={2}>
                    <Typography variant="h4" color="text.primary" whiteSpace={'pre-line'}>
                        {t('email.page.verification.confirmed.subtitle')}
                    </Typography>
                    <Typography>{t('email.page.verification.confirmed.support-text')}</Typography>
                </Stack>
                <ActionButton
                    size="large"
                    variant="contained"
                    href={`${import.meta.env.VITE_LEGACY_APP_ROOT}/login`}
                >
                    {t('email.page.verification.confirmed.action.get-started')}
                </ActionButton>
            </Stack>
            <Box
                component={'img'}
                src="/confirm-email-change/e-mail_3d.webp"
                alt=""
                height={256}
                width={256}
            />
        </>
    );
};

const ErrorMessage = ({ message }: { message: string }) => {
    const { t } = useTranslation();
    return (
        <>
            <Stack gap={4.5}>
                <Typography variant="h3" color="text.primary">
                    {t('email.page.verification.error.title')}
                </Typography>
                <Stack gap={2}>
                    <Typography>{t('email.page.verification.error.support-text')}</Typography>
                    <Typography variant="caption" color="error.light">
                        {t(message)}
                    </Typography>
                </Stack>
                <ActionButton
                    size="large"
                    variant="contained"
                    href={`${import.meta.env.VITE_LEGACY_APP_ROOT}/login`}
                >
                    {t('email.page.verification.error.action.back-to-swonkie')}
                </ActionButton>
                <Typography variant="caption" color="text.secondary">
                    <Trans
                        i18nKey={'email.page.verification.error.helper-text'}
                        components={{
                            email: (
                                <Typography
                                    component={Link}
                                    to={'mailto:sayhello@swonkie.com'}
                                    variant="caption"
                                    color="primary.main"
                                    display="inline-block"
                                    sx={{ textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>
            <Box
                component="img"
                src="/confirm-email-change/warning_3d.webp"
                alt=""
                height={256}
                width={256}
            />
        </>
    );
};

export default function ConfirmEmailChange() {
    const { id } = useParams<{ id: string }>();
    const { setGlobalLoading } = useGlobalLoading();
    const [confirmEmail, { data, error }] = useMutation(ConfirmUserChangeEmailDocument, {
        onCompleted: () => setGlobalLoading(false),
        onError: () => setGlobalLoading(false),
    });

    useEffect(() => {
        if (!id) {
            throw new Error('ConfirmEmailChange id not found');
        }
        setGlobalLoading(true);
        confirmEmail({ variables: { id: id } });
    }, [confirmEmail, id, setGlobalLoading]);

    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <ResponsiveStack direction="row" gap={7.5} alignItems="center" maxWidth={924}>
                {data?.confirmUserChangeEmail && <SuccessMessage />}
                {error && <ErrorMessage message={error.message} />}
            </ResponsiveStack>
        </Stack>
    );
}
