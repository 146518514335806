import { Skeleton, Stack } from '@mui/material';

export const Loading = () => {
    return (
        <Stack>
            {Array.from(new Array(8)).map((v, i) => (
                <Stack key={i} direction="row" gap={2} p={(theme) => theme.spacing(1, 2)}>
                    <Skeleton variant="rounded" height={40} width={40} />
                    <Stack flex={1}>
                        <Skeleton />
                        <Skeleton />
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};
