import { ApolloClient as Client, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

export enum GraphQLKnownErrorCodes {
    FORBIDDEN = 'FORBIDDEN',
    SUBSCRIPTION_REQUIRED = 'SUBSCRIPTION_REQUIRED',
    TEAM_PERMISSION_REQUIRED = 'TEAM_PERMISSION_REQUIRED',
}
export const GRAPHQL_KNOWN_ERROR_CODES = Object.values(GraphQLKnownErrorCodes);

type ForbiddenTypes = `${GraphQLKnownErrorCodes}`;

const loadMoreOptions = (keyArgs: string[] = []) => {
    return {
        keyArgs: keyArgs, //[...keyArgs, 'orderBy'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        merge(existing: any, incoming: any, { args: { skip = 0 } }: any) {
            if (skip === 0) {
                return incoming;
            }

            const mergedItems = existing ? existing.items.slice(0) : [];
            for (let i = 0; i < incoming.items.length; ++i) {
                mergedItems[mergedItems.length + i] = incoming.items[i];
            }

            return {
                items: mergedItems,
                total: skip === 0 ? incoming.total : existing.total,
                hasMore: incoming.hasMore,
            };
        },
    };
};

function makeClient(setForbidden: (val: false | ForbiddenTypes) => void) {
    // Link to validate if user is logged in
    const verifyErrorCodesLink = onError(({ response }) => {
        if (
            response?.errors &&
            response?.errors?.length > 0 &&
            typeof response?.errors[0].extensions?.code === 'string'
        ) {
            const errorCode = response?.errors[0].extensions?.code;
            if (
                errorCode === GraphQLKnownErrorCodes.FORBIDDEN ||
                errorCode === GraphQLKnownErrorCodes.SUBSCRIPTION_REQUIRED ||
                errorCode === GraphQLKnownErrorCodes.TEAM_PERMISSION_REQUIRED
            ) {
                setForbidden(errorCode);
            }
        }
    });

    const httpLink = new HttpLink({
        uri: import.meta.env.VITE_API_ROOT + '/graphql',
        // you can disable result caching here if you want to
        // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
        fetchOptions: { cache: 'no-store' },
        credentials: 'include',
        // you can override the default `fetchOptions` on a per query basis
        // via the `context` property on the options passed as a second argument
        // to an Apollo Client data fetching hook, e.g.:
        // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
    });

    return new Client({
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        workspaces: loadMoreOptions(),
                        notifications: loadMoreOptions(),
                        groupNotifications: loadMoreOptions(['group']),
                    },
                },
                SubscriptionPreview: {
                    keyFields: [],
                },
            },
        }),
        link: verifyErrorCodesLink.concat(httpLink),
    });
}

export function ApolloClient({ children }: React.PropsWithChildren) {
    const [forbidden, setForbidden] = useState<false | ForbiddenTypes>(false);

    // we use window.location.pathname & search because useLocation causes wasted rerenders (known reported issue of react-router)

    let navigateTo: JSX.Element | null = null;
    if (forbidden === GraphQLKnownErrorCodes.FORBIDDEN) {
        // in case of user is not logged in while making a request, redirect to auth
        // send full pathname & search because auth app may need this info
        window.location.href = `${import.meta.env.VITE_LEGACY_APP_ROOT}${window.location.pathname}${window.location.search}`;
    } else if (forbidden === GraphQLKnownErrorCodes.SUBSCRIPTION_REQUIRED) {
        // in case of user not having an active subscription, redirect to expired page
        navigateTo = <Navigate to={'/settings/subscription-expired'} />;
    } else if (forbidden === GraphQLKnownErrorCodes.TEAM_PERMISSION_REQUIRED) {
        navigateTo = <Navigate to={'/team-access-denied'} />;
    }

    return (
        <ApolloProvider client={makeClient(setForbidden)}>
            {navigateTo}
            {children}
        </ApolloProvider>
    );
}
