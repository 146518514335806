import { ChangePaymentMethodDocument } from '@/graphql/generated/payment-method.generated';
import { useMutation } from '@apollo/client';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function PaymentFailedAlert({ onClose }: { onClose: () => void }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [changePaymentMethod, { loading: changingPaymentMethod }] = useMutation(
        ChangePaymentMethodDocument,
        {
            onCompleted: ({ changePaymentMethod }) => {
                window.location.href = changePaymentMethod;
            },
            onError: (error) => enqueueSnackbar(t(error.message)),
        },
    );

    return (
        <Alert
            severity="error"
            icon={<ErrorOutlineRounded />}
            onClose={onClose}
            sx={{
                border: 1,
                borderColor: 'error.states-outlineBorder',
                color: 'error.main',
            }}
        >
            <AlertTitle color="error" sx={{ display: 'flex' }}>
                {t('billing.payment.alert.failed.title')}
                <LoadingButton
                    size="small"
                    color="error"
                    sx={{ m: '0 0 0 auto' }}
                    onClick={() => changePaymentMethod()}
                    loading={changingPaymentMethod}
                >
                    {t('billing.payment.alert.failed.action.update')}
                </LoadingButton>
            </AlertTitle>
            {t('billing.payment.alert.failed.support-text')}
        </Alert>
    );
}
