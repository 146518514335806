import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export default function PaymentSuccessful() {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(5);
    const navigate = useNavigate();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setTimer((count) => count - 1);
        }, 1000);

        return () => {
            intervalRef.current && clearInterval(intervalRef.current);
        };
    }, []);

    useEffect(() => {
        if (timer === 0 && intervalRef.current) {
            clearInterval(intervalRef.current);
            navigate('/settings/workspace/billing');
        }
    }, [timer, navigate]);

    return (
        <Stack height="100vh" alignItems="center" justifyContent="center" flex={1} p={3}>
            <Stack alignItems="center" maxWidth={924} textAlign="center">
                <Box component="img" src="/credit_card_3d.png" alt="" width={256} height={256} />
                <Typography variant="h2" mt={6} color={'success.main'}>
                    {t('payment.success.title')}
                </Typography>
                <Typography variant="h5" mt={2}>
                    {t('payment.success.subtitle')}
                </Typography>
                <Typography color="text.secondary" mt={2}>
                    {t('payment.success.support-text')}
                </Typography>

                <Typography variant="body2" color="text.secondary" mt={4.5}>
                    {t('payment.success.countdown.title')}
                </Typography>

                <Typography variant="h2" mt={1}>
                    {timer}
                </Typography>

                <Typography variant="caption" color="text.secondary" mt={6}>
                    <Trans
                        i18nKey={'payment.success.helper-text'}
                        components={{
                            email: (
                                <Typography
                                    component={Link}
                                    to="mailto:billing@swonkie.com"
                                    variant="caption"
                                    color="primary.main"
                                    display="inline-block"
                                    sx={{ textDecoration: 'none' }}
                                />
                            ),
                        }}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
}
