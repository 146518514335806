import { Navigate, Route, Routes } from 'react-router-dom';
import PublicLayout from './pages/PublicLayout';
import TeamAccessDenied from './pages/public/TeamAccessDenied';
import PrivateLayout from './pages/PrivateLayout';
import ConfirmEmailChange from './pages/public/ConfirmEmailChange';
import NotFound from './pages/public/NotFound';
import PaymentSuccessful from './pages/public/PaymentSuccessful';
import { lazy } from 'react';
import { pathMatch as OldLegacyPaths, redirectHashroutes } from './legacy/components/OldLegacyIframe';
import { pathMatch as LegacyPaths } from './legacy/components/LegacyIframe';
import Subscribe from './pages/private/settings/Subscribe';
import { LegacyPath } from './legacy/components/LegacyIframes';
import PromoCodeTrial from './pages/public/PromoCodeTrial';

redirectHashroutes();

const SettingsWorkspaceBilling = lazy(
    () => import('./pages/private/settings/workspace/SettingsWorkspaceBilling'),
);
const SettingsPersonalGeneral = lazy(
    () => import('./pages/private/settings/personal/SettingsPersonalGeneral'),
);
const SettingsPersonalPrivacy = lazy(
    () => import('./pages/private/settings/personal/SettingsPersonalPrivacy'),
);
const SettingsWorkspaceGeneral = lazy(
    () => import('./pages/private/settings/workspace/SettingsWorkspaceGeneral'),
);
const SubscriptionExpired = lazy(() => import('./pages/private/settings/SubscriptionExpired'));

export default function AppRoutes() {
    // URL "/settings/billing/promoCode/" - due to legacy compatibility issues

    return (
        <Routes>
            <Route element={<PrivateLayout />}>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/settings/personal/general" element={<SettingsPersonalGeneral />} />
                <Route path="/settings/personal/privacy" element={<SettingsPersonalPrivacy />} />
                <Route path="/settings/workspace/general" element={<SettingsWorkspaceGeneral />} />
                <Route path="/settings/workspace/billing" element={<SettingsWorkspaceBilling />} />
                <Route path="/settings/subscription-expired" element={<SubscriptionExpired />} />
                <Route path="/subscribe/:id" element={<Subscribe />} />
                {[...OldLegacyPaths, ...LegacyPaths].map((paths: LegacyPath) => {
                    const routes = [<Route path={`/${paths.newPath}/*`} />];
                    if (paths.oldPath != paths.newPath) {
                        routes.push(<Route path={`/${paths.oldPath}/*`} />);
                    }
                    return routes;
                })}
            </Route>
            <Route element={<PublicLayout />}>
                <Route path="/team-access-denied" element={<TeamAccessDenied />} />
                <Route path="/confirm-email-change/:id" element={<ConfirmEmailChange />} />
                <Route path="/payment-successful" element={<PaymentSuccessful />} />
                <Route path="/settings/billing/promoCode/*" element={<PromoCodeTrial />} />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route
                path="/login"
                Component={() => {
                    window.location.replace(`${import.meta.env.VITE_LEGACY_APP_ROOT}/login`);
                    return null;
                }}
            />
            <Route
                path="/register"
                Component={() => {
                    window.location.replace(`${import.meta.env.VITE_LEGACY_APP_ROOT}/register`);
                    return null;
                }}
            />
        </Routes>
    );
}
