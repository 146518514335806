import MyBox from '@/components/MyBox';
import PageLayout from '@/components/PageLayout';
import { Grid, Skeleton } from '@mui/material';

export default function PageLayoutLoading() {
    // You can add any UI inside Loading, including a Skeleton.
    return (
        <PageLayout>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MyBox>
                        <Skeleton width={'25%'} />
                        <Skeleton width={'100%'} height="50px" />
                        <Skeleton width={'100%'} height="50px" />
                        <Skeleton width={'70px'} height="50px" />
                    </MyBox>
                </Grid>
                <Grid item xs={12}>
                    <MyBox>
                        <Skeleton width={'25%'} />
                        <Skeleton width={'100%'} height="50px" />
                        <Skeleton width={'100%'} height="50px" />
                        <Skeleton width={'70px'} height="50px" />
                    </MyBox>
                </Grid>
            </Grid>
        </PageLayout>
    );
}
