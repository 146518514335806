import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PostHogPageView(): null {
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = location.search;
    const posthog = usePostHog();
    // Track pageviews
    useEffect(() => {
        if (pathname && posthog) {
            let url = window.origin + pathname;
            if (searchParams.toString()) {
                url = url + `?${searchParams.toString()}`;
            }
            posthog.capture('$pageview', {
                $current_url: url,
            });
        }
    }, [pathname, searchParams, posthog]);

    return null;
}
