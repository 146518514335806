import {
    Badge,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemTextProps,
    Typography,
} from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useNavigation } from '@/providers/navigation-provider';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useHelpResources } from '@/providers/help-resources-provider';

const StyledListItemText = styled(ListItemText)<ListItemTextProps>(() => ({
    //color: theme.palette.warning.main,
}));

export default function MainHelpResources() {
    const { t } = useTranslation();
    const { mainNavOpen } = useNavigation();
    const { openHelpResources } = useHelpResources();

    return (
        <>
            <ListItemButton
                dense={true}
                sx={{ borderRadius: '99px' }}
                {...(!mainNavOpen && { sx: { justifyContent: 'center' } })}
                onClick={openHelpResources}
            >
                <ListItemIcon
                    sx={{
                        ...(!mainNavOpen && { minWidth: 0 }),
                    }}
                >
                    <Badge variant="dot" color="warning" invisible={/*mainNavOpen*/ true}>
                        <HelpRoundedIcon />
                    </Badge>
                </ListItemIcon>
                {mainNavOpen && (
                    <>
                        <StyledListItemText>
                            <Typography noWrap variant="body2">
                                {t('str.help.title')}
                            </Typography>
                        </StyledListItemText>
                        {/*<RelativeBadge variant="dot" color="warning" />*/}
                    </>
                )}
            </ListItemButton>
        </>
    );
}
